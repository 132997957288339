import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FEATURE_KEY, PermissionsState } from './state';
import * as fromReducers from './reducers';

export const featureSelector =
	createFeatureSelector<PermissionsState>(FEATURE_KEY);

export const selectPermissionsLoading = createSelector(
	featureSelector,
	(state) => state.loading,
);

export const selectPermissions = createSelector(
	featureSelector,
	fromReducers.selectAll,
);

export const selectPermissionsError = createSelector(
	featureSelector,
	(state) => state.error,
);

export const selectPermissionsLoaded = createSelector(
	featureSelector,
	(state) => state.loaded,
);
