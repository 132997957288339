import { createAction, props } from '@ngrx/store';

import { PermissionApp } from '@emrm/permissions/types';

const ACTIONS_CODE = 'Permissions';
const ACTIONS_API_CODE = `[${ACTIONS_CODE} API]`;

export const loadPermissions = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Load`,
);

export const loadPermissionsSuccess = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Load Success`,
	props<{ permissions: PermissionApp[] }>(),
);

export const loadPermissionsFailure = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Load Failure`,
	props<{ error: string }>(),
);
