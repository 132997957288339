import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { PermissionApp } from '@emrm/permissions/types';

import * as StoreActions from './actions';
import { PermissionsState } from './state';

export const adapter = createEntityAdapter<PermissionApp>();

export const initialState: PermissionsState = adapter.getInitialState({
	loading: false,
	error: null,
	loaded: false,
});

export const reducers = createReducer(
	initialState,
	on(StoreActions.loadPermissions, (state) => {
		return {
			...state,
			loading: true,
		};
	}),
	on(StoreActions.loadPermissionsSuccess, (state, action) => {
		return adapter.setAll(action.permissions, {
			...state,
			loading: false,
			loaded: true,
		});
	}),
	on(StoreActions.loadPermissionsFailure, (state, action) => ({
		...state,
		loading: false,
		error: action.error,
	})),
);

export const { selectAll, selectEntities } = adapter.getSelectors();
