import { EntityState } from '@ngrx/entity';

import { PermissionApp } from '@emrm/permissions/types';

export const FEATURE_KEY = 'permissions';

export interface PermissionsState extends EntityState<PermissionApp> {
	loading: boolean;
	error: string | null;
	loaded: boolean;
}
